import React, {Component} from "react";

import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import {Bar} from "react-chartjs-2";
import DeviceStore from "../../stores/DeviceStore";
import moment from "moment";
import StatisticsFilter from "../../components/StatisticsFilter";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import StatisticsTable from "./StatiscticsTable";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import theme from "../../theme";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";

const tabs = [
    {value: 'rssi', name: 'RSSI', url: 'rssi', color: "rgb(255,99,122)", yAxes: 'RSSI [dbm]'},
    {value: 'snr', name: 'SNR', url: 'snr', color: "rgb(0,103,166)", yAxes: 'SNR [db]'},
    {value: 'uplink', name: 'Uplink Counts', url: 'dataupCounts', color: "rgb(255,205,86)", yAxes: 'Counts'},
    {value: 'downlink', name: 'Downlink Counts', url: 'datadownCounts', color: "rgb(11,176,134)", yAxes: 'Counts'}
]

const styles = {
    tabs: {
        borderBottom: "1px solid " + theme.palette.divider,
        height: "48px",
        overflow: "visible",
        marginLeft: '30px',
        marginRight: '30px'
    },
    tab: {
        minWidth: '100px'
    }
};

class DeviceStats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            to: new Date(new Date().setHours(23,59,59)).toJSON(),
            from: new Date(new Date(new Date().setDate(new Date().getDate()-5)).setHours(0,0,0)).toJSON(),
            order: 'asc',
            stats: undefined,
            max: undefined || 0,
            min: undefined || 0,
            average: undefined || 0,
            labels: undefined,
            gateways: undefined,
            tableData: [],
            isBar: true,
            tab: 2,
            loaded: 0
        };

        this.setDate = this.setDate.bind(this);
        this.setMode = this.setMode.bind(this);
        this.getStats = this.getStats.bind(this);
        this.getRows = this.getRows.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
    }

    componentDidMount() {
        this.getStats();
    }

    getStats(from = this.state.from, to = this.state.to, type = this.state.tab){
        this.setState({loaded: this.state.loaded+1, stats: undefined});
        DeviceStore.getStats(
          tabs[type].url,
          this.props.device.devEUI,
          {
              order:this.state.order,
              from: from,
              to: to
          },
          (data)=>{
              this.setState({
                  stats: data.result.map((item)=>{
                      return tabs[type].value === 'rssi'
                        ? item.rssi
                        : tabs[type].value === 'snr'
                          ? item.loRaSNR
                          : item.messagesCount
                  }),
                  labels: data.result.map((item)=>{
                      return tabs[type].value === 'uplink' || tabs[type].value === 'downlink'
                        ?  moment(item.date).format("DD.MM.YYYY")
                        :  moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")
                  }),
                  gateways: tabs[type].value === 'uplink' || tabs[type].value === 'downlink' ? undefined : data.result.map((item)=>item.gatewayID),
                  min: data.min,
                  max: data.max,
                  average: data.average,
                  tableData: data.result,
                  loaded: this.state.loaded-1
              })
          }
        );
    }

    setDate(from, to) {
        this.setState({
            from: new Date(new Date(from).setHours(0,0,0)).toJSON(),
            to: new Date(new Date(to).setHours(23,59,59)).toJSON()
        });
        this.getStats(new Date(new Date(from).setHours(0,0,0)).toJSON(), new Date(new Date(to).setHours(23,59,59)).toJSON());
    }

    setMode(value) {
        this.setState({
            isBar: value
        });
    }

    getRows() {
        return this.state.tableData.map((item, i)=>{
            return(
              <TableRow key={i}>
                  <TableCell>{moment(item.date).format('DD.MM.YYYY HH:mm:ss')}</TableCell>
                  <TableCell>{item.messagesCount}</TableCell>
              </TableRow>
            );
        })
    }

    onChangeTab(e,v){
        this.setState({
            tab: v,
            to: new Date(new Date().setHours(23,59,59)).toJSON(),
            from: new Date(new Date(new Date().setDate(new Date().getDate()-5)).setHours(0,0,0)).toJSON(),
        });
        this.getStats(new Date(new Date(new Date().setDate(new Date().getDate()-5)).setHours(0,0,0)).toJSON(), new Date(new Date().setHours(23,59,59)).toJSON(), v);
    }

    render() {
        let gateways = this.state.gateways ? this.state.gateways : undefined;

        const data ={
            labels: this.state.isBar ? this.state.labels && this.state.labels.slice(0, 999) : this.state.labels,
            datasets: [
                {
                    backgroundColor: tabs[this.state.tab].color,
                    data: this.state.isBar ? this.state.stats && this.state.stats.slice(0, 999) : this.state.stats,
                }
            ]
        }

        const options = {
            responsive: true,
            legend: {
                display: false
            },
            title: {
                display: true,
                text: [tabs[this.state.tab].name, 'Min: ' + this.state.min + ', Max: ' + this.state.max + ', Average: ' + this.state.average],
                fontSize: 18
            },
            scales: {
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: tabs[this.state.tab].yAxes,
                    },
                    ticks: {
                        suggestedMin: 0,
                        suggestedMax: 0
                    }
                }]
            },
            tooltips : {
                callbacks: {
                    label: function(tooltipItem, data) {
                        return gateways ? tooltipItem.yLabel + ', Gateway: ' + gateways[tooltipItem.index] : tooltipItem.yLabel
                    }
                }
            },
        }

        return (
          <Grid container spacing={24}>
              <Grid item xs={12}>
                  <Card className={this.props.classes.card}>
                      <CardContent>
                          <Grid item xs={12}>
                              <Tabs
                                value={this.state.tab}
                                indicatorColor="primary"
                                className={this.props.classes.tabs}
                                varinat={'fullWidth'}
                                onChange={this.onChangeTab}
                              >
                                  <Tab label="RSSI" className={this.props.classes.tab}/>
                                  <Tab label="SNR" className={this.props.classes.tab}/>
                                  <Tab label="Uplink" className={this.props.classes.tab}/>
                                  <Tab label="Downlink" className={this.props.classes.tab}/>
                              </Tabs>
                          </Grid>

                          <StatisticsFilter  setDate={this.setDate} from={this.state.from} to={ this.state.to} isBar={this.state.isBar} setMode={this.setMode}/>
                          <div style={{padding: '30px'}}>
                              {this.state.loaded === 0 &&
                              <Grid item xs={12}>
                                  {this.state.isBar && [
                                      this.state.stats && this.state.stats.length > 1000 &&
                                      <div style={{textAlign: "center"}}>
                                          <Chip
                                            color="secondary"
                                            label="Too many records for the graph. Switch to table display or select a shorter date range."
                                          />
                                      </div>,
                                      <Bar
                                        data={data}
                                        options={options}
                                        height={60}
                                      />]
                                  }
                                  {!this.state.isBar &&
                                  <StatisticsTable tableData={this.state.tableData} devEUI={this.props.match.params.devEUI} type={tabs[this.state.tab].value} tab={this.state.tab}/>
                                  }
                              </Grid>
                              }
                              {this.state.loaded !== 0 &&
                              <Grid item xs={12} style={{textAlign: "center"}}>
                                  <CircularProgress/>
                              </Grid>
                              }
                          </div>
                      </CardContent>
                  </Card>
              </Grid>
          </Grid>
        );
    }
}

export default withStyles(styles)(DeviceStats);
