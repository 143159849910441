import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Export from "mdi-material-ui/Export";
import Button from "@material-ui/core/Button";
import fileDownload from "js-file-download";

const tabs = [
    {value: 'rssi', tableHeaders: ['Time', 'RSSI [dbm]', 'Gateway'], dataKey: ['createdAt', 'rssi', 'gatewayID'], format: 'DD.MM.YYYY HH:mm:ss'},
    {value: 'snr', tableHeaders: ['Time', 'SNR [db]', 'Gateway'], dataKey: ['createdAt', 'loRaSNR', 'gatewayID'], format: 'DD.MM.YYYY HH:mm:ss'},
    {value: 'uplink', tableHeaders: ['Date', 'Messages count'], dataKey: ['date', 'messagesCount'], format: 'DD.MM.YYYY'},
    {value: 'downlink', tableHeaders: ['Date', 'Messages count'], dataKey: ['date', 'messagesCount'], format: 'DD.MM.YYYY'}
]

class StatisticsTable extends Component {
    constructor(props) {
        super();
        this.downloadCsv = this.downloadCsv.bind(this);
        this.excelFormatFromResult = this.excelFormatFromResult.bind(this);
    }

    excelFormatFromResult(item) {
        let itemResult = {};
        if (tabs[this.props.tab].value === 'rssi') {
            itemResult['Time'] = moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss") ;
            itemResult['RSSI [dbm]'] = item.rssi;
            itemResult['Gateway'] = item.gatewayID;
        }
        else if (tabs[this.props.tab].value === 'snr') {
            itemResult['Date'] = moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss") ;
            itemResult['SNR [db]'] = item.loRaSNR;
            itemResult['Gateway'] = item.gatewayID;
        }
        else{
            itemResult['Date'] = moment(item.date).format("DD.MM.YYYY") ;
            itemResult['Messages count'] = item.messagesCount;
        }
        return itemResult;
    }

    downloadCsv(rows) {
        let csvRecord; //csvContent = "data:text/csv;charset=utf-8,";

        if (rows.length>0) {
            csvRecord = Object.keys(this.excelFormatFromResult(rows[0])).join(',') + '\n';

            rows.map(e => {
                let row = this.excelFormatFromResult(e);
                return csvRecord += Object.values(row).join(',') + '\n';
            });
        }
        fileDownload(csvRecord, `${this.props.type}-stats-${this.props.devEUI}.csv`);
        /* let encodedUri = encodeURI(csvContent + csvRecord);

        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${this.props.type}-stats-${this.props.devEUI}.csv`);
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);*/
    }

    render() {
        return(
          <Grid>
              <Table>
                  <TableHead>
                      <TableRow>
                          {tabs[this.props.tab].tableHeaders.map((header, i)=>
                            <TableCell key={i}>{header}</TableCell>
                          )}
                          <TableCell><Button variant="outlined"  style={{float: 'right'}}  onClick = {() => {this.downloadCsv(this.props.tableData)}} ><Export/>Export</Button></TableCell>
                      </TableRow>
                  </TableHead>
                  <TableBody>
                      {this.props.tableData.map((item, i) => {
                          return (
                            <TableRow key={i}>
                                {tabs[this.props.tab].dataKey.map((key, j)=>
                                  j === 0
                                    ? <TableCell key={j}>{moment(item[key]).format(tabs[this.props.tab].format)}</TableCell>
                                    : <TableCell key={j}>{item[key]}</TableCell>
                                )}
                                <TableCell></TableCell>
                            </TableRow>
                          );
                      })
                      }
                  </TableBody>
              </Table>
          </Grid>
        );
    }
}

export default StatisticsTable;
