import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";

import UserStore from "../../stores/UserStore";
import UserFilter from "./UserFilter";


class ListUsers extends Component {
  constructor(props) {
    super(props);

    this.state={
      limit: 10,
      offset: 0,
      search: undefined,
      active: 'all',
      admin: 'all',
      orderBy: 'username',
      order: 'asc'
    };

    this.onSearch = this.onSearch.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    UserStore.list(this.state.search, limit, offset, this.state.active, this.state.admin, this.state.order, this.state.orderBy, callbackFunc);
  }

  getRow(obj) {
    let active = null;
    let admin = null;

    if (obj.isAdmin) {
      admin = <Check />;
    } else {
      admin = <Close />;
    }

    if (obj.isActive) {
      active = <Check />;
    } else {
      active = <Close />;
    }

    return(
      <TableRow key={obj.id}>
        <TableCellLink to={`/users/${obj.id}`}>{obj.username}</TableCellLink>
        <TableCell>{active}</TableCell>
        <TableCell>{admin}</TableCell>
      </TableRow>
    );
  }

  onSearch(search, active, admin, order, orderBy){
    this.setState({
      search: search,
      active: active,
      admin: admin,
      order: order,
      orderBy: orderBy,
    });
    this.getPage(this.state.limit, this.state.offset,  ()=>{});
  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar
          title="Users"
          buttons={[
            <TitleBarButton
              key={1}
              label="Create"
              icon={<Plus />}
              to={`/users/create`}
            />,
          ]}
        >
          <TitleBarTitle title="Users" />
        </TitleBar>
        <Grid item xs={12}>
          <UserFilter onSearch={this.onSearch} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>Username</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Admin</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ListUsers;
