import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';

import Paper from "../../components/Paper";
import Grid from "@material-ui/core/es/Grid/Grid";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import grey from "@material-ui/core/colors/grey";
import ChevronDown from "mdi-material-ui/ChevronDown";
import ChevronUp from "mdi-material-ui/ChevronUp";

const styles = {
  tableRow: {
    backgroundColor: grey[100],
    height: '40px'
  },
  tableHead: {
    backgroundColor: grey[200],
    height: '40px'
  },
  addingRow: {
    padding: '0px 10px 0px 10px !important'
  },
};

class RolledRowsTable extends Component {
  constructor(props) {
    super();

    this.state = {
      count: props.data.totalCount || 0,
      rolledRow: undefined,
      rows: props.data.items
    };

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.showMore = this.showMore.bind(this);
  }

  onChangePage(event, page) {
    this.setState({
      rolledRow: undefined
    });
    this.props.getPage(this.props.limit, (page) * this.props.limit);
  }

  onChangeRowsPerPage(event) {
    this.setState({
      rolledRow: undefined
    });

    this.props.getPage(event.target.value, 0);
  }

  showMore(obj){
    if(!this.state.rolledRow || obj.id !== this.state.rolledRow) {
      this.setState({rolledRow: obj.id});
    }
    else{
      this.setState({rolledRow: undefined});
    }
  }

  getDescendantProp (obj, property) {
    var arr = property.split('.');
    while (arr.length && (obj = obj[arr.shift()]));
    return obj;
  }

  getRow(obj) {
    return(
      <React.Fragment key={obj.id}>
        <TableRow key={obj.id} onClick={()=>this.showMore(obj)}>
          {this.props.configNotification.map((col, i)=>
            col.value === 'createdAt'
              ? <TableCell key={i}>{moment(obj[col.value]).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
              : <TableCell key={i}>{this.getDescendantProp(obj, col.value) !== undefined ? this.getDescendantProp(obj, col.value).toString() : obj[col.value] === false ? 'false' : '' }</TableCell>
          )
          }
          {this.state.rolledRow === obj.id && obj.rxInfo && <TableCell><ChevronUp/></TableCell>}
          {this.state.rolledRow !== obj.id && obj.rxInfo && <TableCell><ChevronDown/></TableCell>}
        </TableRow>
        {this.state.rolledRow === obj.id && obj.rxInfo &&
        <TableRow key={'R'+obj.id}>
          <TableCell colSpan='9' className={this.props.classes.addingRow}>
            <Table>
              <TableHead>
                <TableRow className={this.props.classes.tableHead}>
                  <TableCell>Gateway ID</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Rssi</TableCell>
                  <TableCell>LoRaSNR</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {obj.rxInfo && obj.rxInfo.map((info, i) => {
                  return (
                    <TableRow key={i} className={this.props.classes.tableRow}>
                      <TableCell>{info.gatewayID}</TableCell>
                      <TableCell>{moment(info.time).format("DD.MM.YYYY HH:mm:ss")}</TableCell>
                      <TableCell>{info.rssi}</TableCell>
                      <TableCell>{info.loRaSNR}</TableCell>
                    </TableRow>)
                })
                }
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
        }
      </React.Fragment>
    );
  }

  render() {
    return(
      <Paper>
        {this.props.children &&
        <Grid item xs={12} className={this.props.classButton}>
          <p>
            {this.props.children}
          </p>
        </Grid>
        }
        <Table className={this.props.classes.table}>
          <TableHead>
            {this.props.header}
          </TableHead>
          <TableBody>
            {this.props.data.items.map((row)=>{
              return this.getRow(row);
            })
            }
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={this.props.data.totalCount}
          rowsPerPage={this.props.limit}
          page={this.props.page}
          onChangePage={this.onChangePage}
          onChangeRowsPerPage={this.onChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

export default withStyles(styles)(RolledRowsTable);
