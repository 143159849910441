import sessionStore from "./SessionStore";
import {errorHandler} from "./helpers";

class InvoiceStore {

    list(month, year, callbackFnc){
        fetch('/api/invoices?month=' + month + '&year=' + year,
            {
                method: 'GET',
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization':sessionStore.getToken()
                }
            })
          .then(res=> res.json())
          .then((res) => {
            if(res.message) {
              throw res;
            }
            else{
              callbackFnc(res)
            }
          }).catch(errorHandler)
    }
}

const invoiceStore = new InvoiceStore();
export default invoiceStore;
