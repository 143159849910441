import React, {Component} from "react";

import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Form from "../../components/Form";
import theme from "../../theme";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import DeviceQueue from "../../stores/DeviceQueue";
import DeviceStore from "../../stores/DeviceStore";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const styles = {
    buttons: {
        textAlign: "right",
    },
    button: {
        marginLeft: 2 * theme.spacing.unit,
    },
    icon: {
        marginRight: theme.spacing.unit,
    },
    center: {
        textAlign: "center",
    },
    progress: {
        marginTop: 4 * theme.spacing.unit,
    },
};

const DATA_HEX_FORMAT = "hex"
const DATA_BASE64_FORMAT = "base64"

class DeviceDownlink extends Component {

    state = {
        data: '',
        confirmed: false,
        fPort: '',
        dataAsHex:  false,
        dataFormat: DATA_BASE64_FORMAT
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    hexToBase64(hexstring) {
        return btoa(hexstring.match(/\w{2}/g).map(function(a) {
            return String.fromCharCode(parseInt(a, 16))
        }).join(""));
    }


    validHexFormat(hexString) {
        return hexString.match(/^[0-9a-fA-F]+$/)
    }

    validBase64Format(base64String) {
        return base64String.match(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/)
    }

    onSubmit(e) {
        const {device} = this.props;
        e.preventDefault();
        let data = this.state.data;

        if(this.state.dataFormat === DATA_HEX_FORMAT && !this.validHexFormat(data)){
            DeviceStore.notifyError("Downlink data is not in a valid HEX format");
            return;
        }else if (this.state.dataFormat === DATA_BASE64_FORMAT && !this.validBase64Format(data)){
            DeviceStore.notifyError("Downlink data is not in a valid base64 format");
            return;
        }


        if(this.state.dataFormat === DATA_HEX_FORMAT){
            data = this.hexToBase64(data)
        }

        const body = {
            "devEUI": device.devEUI,
            "confirmed": this.state.confirmed,
            "data": data,
            "fPort": this.state.fPort
        };

        DeviceQueue.enqueue(device, body, resp => {
            this.setState({
                data: '',
                confirmed: false,
                fPort: '',
                dataFormat: DATA_BASE64_FORMAT

            })
        });
    }


    render() {

        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Card className={this.props.classes.card}>
                        <CardContent>
                            <Form
                                submitLabel={'Submit'}
                                onSubmit={this.onSubmit}
                            >

                                <TextField
                                    id="data"
                                    label="Data"
                                    margin="dense"
                                    value={this.state.data}
                                    onChange={(e) => this.setState({data: e.target.value})}
                                    fullWidth
                                    required
                                />
                                <FormControl margin="none">
                                    <RadioGroup
                                        value={this.state.dataFormat}
                                        onChange={(e) => this.setState({dataFormat: e.target.value})}
                                        row
                                        aria-label="dataFormat" name="dataFormat">
                                        <FormControlLabel value={DATA_BASE64_FORMAT} control={<Radio color="default" />} label="Base64 encoded" labelPlacement="end"/>
                                        <FormControlLabel value={DATA_HEX_FORMAT} control={<Radio color="default" />} label="Hex encoded" labelPlacement="end"/>
                                    </RadioGroup>
                                </FormControl>

                                <TextField
                                    id="fPort"
                                    label="fPort"
                                    helperText=""
                                    margin="normal"
                                    value={this.state.fPort}
                                    onChange={(e) => this.setState({fPort: e.target.value})}
                                    inputProps={{
                                        pattern: "[\\d-]+",
                                    }}
                                    fullWidth
                                    required
                                />

                                <FormControl margin="normal">
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Confirmed"
                                            control={
                                                <Checkbox
                                                    id="confirmed"
                                                    checked={this.state.confirmed}
                                                    onChange={(e) => this.setState({confirmed: !this.state.confirmed})}
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(DeviceDownlink);
