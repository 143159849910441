import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import moment from "moment";
import PowerPlug from "mdi-material-ui/PowerPlug";

import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import DeviceStore from "../../stores/DeviceStore";
import theme from "../../theme";
import DeviceFilter from "../devices/DeviceFilter";
import Checkbox from "@material-ui/core/Checkbox";
import MassDownlinkForm from "./MassDownlinkForm";
import TitleBarButton from "../../components/TitleBarButton";
import Import from "mdi-material-ui/Import";
import CheckIcon from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import DeviceQueue from "../../stores/DeviceQueue";
import SimpleTable from "../../components/SimpleTable";
import Exclamation from "mdi-material-ui/AlertOutline";

const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing.unit,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
};

class MassDownlinkResultRow extends Component {
  render() {
    let device = this.props.device;
    let errorResponseStyle = {
      color: theme.palette.secondary.main
    }

    let okResponseStyle = {
      color: theme.palette.primary.main
    }

    return (
      <TableRow key={device.devEUI}>
        <TableCell style={device.fCnt === 0 ? errorResponseStyle : okResponseStyle}>
          {device.fCnt !== 0 &&
            <CheckIcon/>
          }
          {device.fCnt === 0 &&
            <Close/>
          }
        </TableCell>
        <TableCell>{device.devEUI}</TableCell>
        <TableCell style={device.fCnt === 0 ? errorResponseStyle : okResponseStyle}>{device.errorMessage}</TableCell>
      </TableRow>
    );
  }
}

class MassDownlink extends Component {
  constructor() {
    super();

    this.state = {
      search: '',
      deviceProfile: 'all',
      orderBy: 'name',
      order: 'asc',
      selectedDevEUI: [],
      devices: [],
      missingDevEUI: [],
      massDownlinkResult: [],
      selectedAll: false
    };

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.isSelectedAll = this.isSelectedAll.bind(this);
    this.onSelectAll = this.onSelectAll.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount(){
    this.getPage((resp)=> this.setState({
      devices: resp.result.map((device)=>{return {devEUI: device.devEUI}})
    }));
  }

  getPage(callbackFunc, search, deviceProfile, order, orderBy){
    DeviceStore.list({
      applicationID: this.props.match.params.applicationID,
      search: search ? search : this.state.search,
      device_profile_id: deviceProfile && deviceProfile !== 'all' ? deviceProfile : this.state.deviceProfile !== 'all' ?  this.state.deviceProfile : undefined,
      order: order ? order : this.state.order,
      orderBy: orderBy ? orderBy : this.state.orderBy,
      limit: 1000000000000
    }, (resp)=> {
      callbackFunc(resp);
    })
  }

  isSelected(devEUI){
    return this.state.selectedDevEUI.findIndex((device)=>{return device.devEUI === devEUI}) > -1;
  }

  onSelect(devEUI){
    let newSelectedDevEUI = this.state.selectedDevEUI.findIndex((device)=>{return device.devEUI === devEUI}) > -1
      ? this.state.selectedDevEUI.filter((device) => device.devEUI !== devEUI)
      : this.state.selectedDevEUI.concat({devEUI: devEUI});
    this.setState({
      selectedDevEUI: newSelectedDevEUI,
      selectedAll: newSelectedDevEUI.length === this.state.devices.length,
      missingDevEUI: [],
      massDownlinkResult: []
    })
  }

  isSelectedAll(){
    return this.state.selectedAll;
  }

  onSelectAll(){
    this.setState({
      selectedAll: !this.state.selectedAll,
      selectedDevEUI: this.state.selectedAll ? [] : this.state.devices,
      missingDevEUI: [],
      massDownlinkResult: []
    })
  }

  getRow(obj) {
    let lastseen = "n/a";
    let margin = "n/a";
    let battery = "n/a";

    let iconStyle = {
      display: 'inline-flex',
      verticalAlign: 'middle',
      paddingRight: '5px'
    };

    let icon = <Exclamation style={iconStyle}/>;
    let warning = obj.secondsOffline && parseInt(obj.secondsOffline) > 14400;

    if (obj.lastSeenAt !== undefined && obj.lastSeenAt !== null) {
      lastseen = moment(obj.lastSeenAt).fromNow();
    }

    if (!obj.deviceStatusExternalPowerSource && !obj.deviceStatusBatteryLevelUnavailable) {
      battery = `${obj.deviceStatusBatteryLevel}%`
    }

    if (obj.deviceStatusExternalPowerSource) {
      battery = <PowerPlug />;
    }

    if (obj.deviceStatusMargin !== undefined && obj.deviceStatusMargin !== 256) {
      margin = `${obj.deviceStatusMargin} dB`;
    }

    return(
      <TableRow key={obj.devEUI}>
        <TableCell>
          <Checkbox
            checked={this.isSelected(obj.devEUI)}
            onChange={()=>this.onSelect(obj.devEUI)}
            color="primary"
          />
        </TableCell>
        <TableCell style={{color: warning ? theme.palette.secondary.main : theme.palette.primary.main}}>{lastseen}</TableCell>
        <TableCellLink
          color={warning ? 'secondary' : 'primary'}
          to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/devices/${obj.devEUI}`}>
          {warning ? icon : null}
          {obj.name}
        </TableCellLink>
        <TableCell>{obj.devEUI}</TableCell>
        <TableCell>{margin}</TableCell>
        <TableCell>{battery}</TableCell>
      </TableRow>
    );
  }

  onSearch(search, deviceProfile, order, orderBy){
    this.setState({
      search: search,
      deviceProfile: deviceProfile,
      order: order,
      orderBy: orderBy
    });
    this.getPage((resp)=> {this.setState({
      devices: resp.result.map((device)=>{return {devEUI: device.devEUI}}),
      selectedDevEUI: [],
      selectedAll: false
    })}, search, deviceProfile, order, orderBy);
  }

  handleImport(event) {
    let file =  event.target.files[0];
    const scope = this;
    let reader = new FileReader();
    reader.onload = function (e) {
      let csv = e.target.result;
      let lines = csv.split("\n");
      let result = [];
      let missingResult = [];
      let headers=lines[0].split(",");
      for(let i=1;i<lines.length;i++){
        let obj = {};
        let currentline=lines[i].split(",");
        for(let j=0;j<headers.length;j++){
          obj[headers[j].trim()] = currentline[j] ? currentline[j].trim() : undefined;
        }
        if(obj['Device EUI'] && scope.state.devices.findIndex((device)=>{return device.devEUI === obj['Device EUI']}) > -1){
          result.push({devEUI: obj['Device EUI']});
        }
        else if(obj['Device EUI'] && scope.state.devices.findIndex((device)=>{return device.devEUI === obj['Device EUI']}) === -1){
          missingResult.push(obj['Device EUI']);
        }
      }
      scope.setState({
        selectedDevEUI: result,
        missingDevEUI: missingResult,
        massDownlinkResult: []
      });

      if(missingResult.length > 0){
        DeviceStore.notifyMissingDeviceEUI(missingResult.toString());
      }
    };
    reader.readAsText(file);
  }

  onInputClick(event) {
    event.target.value = '';
  }

  onSubmit(confirmed, data, fPort) {
    const body = {
      "massDeviceQueueItem": {
        "devices": this.state.selectedDevEUI,
        "confirmed": confirmed,
        "data": data,
        "fPort": fPort
      }
    };

    DeviceQueue.massEnqueue(body, (resp) => {
      let massDownlinkResult = [];

      if (resp.devices && resp.devices.length > 0) {
        resp.devices.map((device) => {
          return massDownlinkResult.push(device);
        });
      }
      this.setState({
        selectedDevEUI: [],
        devices:[],
        missingDevEUI: [],
        selectedAll: false,
        massDownlinkResult: massDownlinkResult
      })
      this.getPage((resp)=> {this.setState({
        devices: resp.result.map((device)=>{return {devEUI: device.devEUI}}),
      })});
    });
  }

  getResponse() {
    return this.state.massDownlinkResult.map((dev)=>{
      return(
        <MassDownlinkResultRow device={dev} key={dev.devEUI}/>
      );
    })

  }

  render() {
    return(
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <DeviceFilter applicationID={this.props.match.params.applicationID} onSearch={this.onSearch} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>
                  <Checkbox
                    checked={this.isSelectedAll()}
                    onChange={()=>this.onSelectAll()}
                    color="primary"
                  />
                  All
                </TableCell>
                <TableCell>Last seen</TableCell>
                <TableCell>Device name</TableCell>
                <TableCell>Device EUI</TableCell>
                <TableCell>Link margin</TableCell>
                <TableCell>Battery</TableCell>
              </TableRow>
            }
            getRow={this.getRow}
            getPage={this.getPage}
            withoutPagination={true}
            classButton={this.props.classes.buttons}
          >
            <input id={'devEUI-import'}
                   type="file"
                   accept=".csv"
                   ref={(ref) => this.importInput = ref}
                   style={{display: 'none'}}
                   onClick={(e) => this.onInputClick(e)}
                   onChange={(e) => this.handleImport(e)}
            />
            <TitleBarButton
              label="Import Device EUI"
              icon={<Import />}
              onClick = {() => {this.importInput.click()}}
            />
          </DataTable>
        </Grid>
        <Grid item xs={12}>
          <MassDownlinkForm
            devEUISelected={this.state.selectedDevEUI.length > 0}
            onSubmit={this.onSubmit} {...this.props}/>
        </Grid>
        {this.state.massDownlinkResult && this.state.massDownlinkResult.length > 0 &&
        <Grid item xs={12}>
          <SimpleTable
            header={
              <TableRow>
                <TableCell>Success</TableCell>
                <TableCell>Device EUI</TableCell>
                <TableCell>Message</TableCell>
              </TableRow>
            }
            rows={this.getResponse()}
          />
        </Grid>
        }
      </Grid>
    );
  }
}

export default withStyles(styles)(MassDownlink);
