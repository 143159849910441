import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";

import OrganizationStore from "../../stores/OrganizationStore";
import TemplateFilter from "./TemplateFilter";
import Button from "@material-ui/core/Button";


class ListTemplates extends Component {
    constructor(props) {
        super(props);

        this.state={
            limit: 10,
            offset: 0,
            search: undefined,
            canHaveTemplates: 'all',
            orderBy: 'name',
            order: 'asc'
        };

        this.onSearch = this.onSearch.bind(this);
        this.getPage = this.getPage.bind(this);
        this.getRow = this.getRow.bind(this);
    }

    getPage(limit, offset, callbackFunc) {
        OrganizationStore.list(this.state.search, limit, offset, this.state.canHaveGateways, this.state.order, this.state.orderBy, true, callbackFunc);
    }

    getRow(obj) {
        let icon = null;

        if (obj.canHaveGateways) {
            icon = <Check />;
        } else {
            icon = <Close />;
        }

        return(
            <TableRow key={obj.id}>
                <TableCellLink to={`/templates/${obj.id}`}>{obj.name}</TableCellLink>
                <TableCell>{obj.displayName}</TableCell>
                <TableCell>{icon}</TableCell>
                <TableCell>
                    <Button color="primary"
                            variant={'contained'}
                            style={{color: 'white', 'alignSelf': 'end'}}
                            onClick={()=>this.props.history.push(`/templates/${obj.id}/create-organization`)}>
                      CREATE ORGANIZATION
                    </Button>
                </TableCell>
            </TableRow>
        );
    }

    onSearch(search, canHaveGateways, orderBy, order){
        this.setState({
            search: search,
            canHaveGateways: canHaveGateways,
            order: order,
            orderBy: orderBy,
        });
        this.getPage(this.state.limit, this.state.offset,  ()=>{});
    }

    render() {
        return(
            <Grid container spacing={24}>
                <TitleBar
                    buttons={[
                        <TitleBarButton
                            key={1}
                            label="Create template"
                            icon={<Plus />}
                            to={`/templates/create`}
                        />,
                    ]}
                >
                    <TitleBarTitle title="Templates" />
                </TitleBar>
                <Grid item xs={12}>
                    <TemplateFilter onSearch={this.onSearch} />
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        header={
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Display name</TableCell>
                                <TableCell>Can have gateways</TableCell>
                                <TableCell>Create from template</TableCell>
                            </TableRow>
                        }
                        getPage={this.getPage}
                        getRow={this.getRow}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default ListTemplates;
