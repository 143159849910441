import sessionStore from "./SessionStore";
import {errorHandler} from "./helpers";
import dispatcher from "../dispatcher";

const NOTIFICATIONS_LIMIT = 10000

class PersisterStore {

  getPersisterDataService(deviceEUI, notification_type, filter, callbackFnc){
    fetch( '/api/devices/' + deviceEUI + '/' + notification_type + '?limit=' + NOTIFICATIONS_LIMIT + '&from=' + filter.from +'&to=' + filter.to,
      {headers:{
          'Content-Type': 'application/json',
          'Authorization':sessionStore.getToken()
        }})
      .then(res => res.json())
      .then((data) => {
        callbackFnc(data)
      }).catch(errorHandler)
  }

  getPersisterDataServiceForTable(deviceEUI, notification_type, filter, limit, offset, order, allRXInfo, callbackFnc){
    fetch( '/api/devices/' + deviceEUI + '/' + notification_type + '?limit=' + limit + '&offset=' + offset + '&order=' + order + '&from=' + filter.from +'&to=' + filter.to + '&allRXInfo=' + allRXInfo,
      {headers:{
          'Content-Type': 'application/json',
          'Authorization':sessionStore.getToken()
        }})
      .then(res => res.json())
      .then((data) => {
        callbackFnc(data)
      }).catch(errorHandler)
  }

  notify(message) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: message,
      },
    });
  }

  notifyError(message) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "error",
        message: message,
      },
    });
  }
}

const persisterStore = new PersisterStore();
export default persisterStore;
