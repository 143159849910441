import React, { Component } from "react";

import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { withStyles } from '@material-ui/core/styles';
import Paper from "./Paper";
import Grid from "@material-ui/core/es/Grid/Grid";

const styles = {

};

class SimpleTable extends Component {
  constructor(props) {
    super();

    this.state = {
      count: props.rows ? props.rows.length : 0,
      rowsPerPage: 10,
      page: 0
    };

    this.getRows = this.getRows.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeRowsPerPage = this.onChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    this.onChangePage(null, 0);
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }
    this.onChangePage(null, 0);

    this.setState({
      count: this.props.rows ? parseInt(this.props.rows.length, 10) : 0
    });
  }

  getRows(){
    let rows = this.props.rows;
    let visibleRows = [];
    if(this.props.withoutPagination){
      return rows;
    }
    if(rows && rows.length>0){
      for(let i= this.state.page * this.state.rowsPerPage; i < (this.state.page + 1) * this.state.rowsPerPage; i++){
        if(rows.length === i) break;
        visibleRows.push(rows[i]);
      }
    }
    return visibleRows;
  }

  onChangePage(event, page) {
    this.setState({
      page: page,
    });
  }

  onChangeRowsPerPage(event) {
    this.setState({
      page: 0,
      rowsPerPage: event.target.value,
    });
  }

  render() {
    if (this.props.rows === undefined) {
      return(<div></div>);
    }

    return(
      <Paper>
        {this.props.children &&
          <Grid item xs={12} className={this.props.classButton}>
            <p>
              {this.props.children}
            </p>
          </Grid>
        }
        <Table className={this.props.classes.table}>
          <TableHead>
            {this.props.header}
          </TableHead>
          <TableBody>
            {this.getRows()}
          </TableBody>
        </Table>
        {!this.props.withoutPagination &&
          < TablePagination
            component="div"
            count={this.state.count}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.onChangePage}
            onChangeRowsPerPage={this.onChangeRowsPerPage}
            />
        }
      </Paper>
    );
  }
}

export default withStyles(styles)(SimpleTable);
