import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Plus from "mdi-material-ui/Plus";
import Exclamation from "mdi-material-ui/AlertOutline";

import moment from "moment";
import { Bar } from "react-chartjs-2";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import GatewayStore from "../../stores/GatewayStore";

import GatewayFilter from "./GatewayFilter";
import theme from "../../theme";
import Check from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";

class GatewayRow extends Component {
  constructor() {
    super();

    this.state = {};
  }

  componentWillMount() {
    const start = moment().subtract(29, "days").toISOString();
    const end = moment().toISOString();

    GatewayStore.getStats(this.props.gateway.id, start, end, resp => {
      let stats = {
        labels: [],
        datasets: [
          {
            data: [],
            fillColor: "rgba(33, 150, 243, 1)",
          },
        ],
      };

      for (const row of resp.result) {
        stats.labels.push(row.timestamp);
        stats.datasets[0].data.push(row.rxPacketsReceivedOK + row.txPacketsEmitted);
      }

      this.setState({
        stats: stats,
      });
    });
  }

  render() {
    let isMonitored;
    const options = {
      scales: {
        xAxes: [{display: false}],
        yAxes: [{display: false}],
      },
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      responsive: false,
      animation: {
        duration: 0,
      },
    };

    let iconStyle = {
      display: 'inline-flex',
      verticalAlign: 'middle',
      paddingRight: '5px'
    };

    let icon = <Exclamation style={iconStyle}/>;
    let warning = this.props.gateway.secondsOffline && parseInt(this.props.gateway.secondsOffline) > 14400;

    let tableCellStyle = {
      paddingRight :'15px',
      paddingLeft:'15px',
    };

    let tableCellLastSeenStyle = {
      paddingRight :'15px',
      paddingLeft:'15px',
      color: warning ? theme.palette.secondary.main : theme.palette.primary.main
    };

    if (this.props.gateway.isMonitored) {
      isMonitored = <Check />;
    } else {
      isMonitored = <Close />;
    }

    return(
      <TableRow>
        <TableCellLink
          style={tableCellStyle}
          color={warning ? 'secondary' : 'primary'}
          to={`/organizations/${this.props.gateway.organizationID}/gateways/${this.props.gateway.id}`}
        >
          {warning ? icon : null}
          {this.props.gateway.name}
        </TableCellLink>
        <TableCell style={tableCellStyle}>{this.props.gateway.id}</TableCell>
        <TableCell style={tableCellStyle}>{this.props.gateway.description}</TableCell>
        <TableCell style={tableCellLastSeenStyle}>{this.props.gateway.lastSeenAt ? moment(this.props.gateway.lastSeenAt).format("DD.MM.YYYY HH:mm:ss") : ''}</TableCell>
        <TableCell style={tableCellStyle}>{isMonitored}</TableCell>
        <TableCell style={tableCellStyle}>
          {this.state.stats && <Bar
            width={380}
            height={23}
            data={this.state.stats}
            options={options}
          />}
        </TableCell>
      </TableRow>
    );
  }
}


const styles = {
  chart: {
    width: 380,
  },
};



class ListGateways extends Component {
  constructor() {
    super();

    this.state={
      limit: 10,
      offset: 0,
      search: undefined,
      connectionType: undefined,
      isMonitored: undefined,
      orderBy: 'last_seen_at',
      order: 'desc'
    };

    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    //GatewayStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc);
    GatewayStore.listActivity(limit, offset, this.props.match.params.organizationID, this.state.search, this.state.connectionType,  this.state.isMonitored, this.state.order, this.state.orderBy, callbackFunc);
  }

  getRow(obj) {
    return(
      <GatewayRow key={obj.id} gateway={obj} />
    );
  }

  onSearch(search, connectionType, isMonitored, orderBy, order){
    this.setState({
      search: search,
      connectionType: connectionType !== 'all' ? connectionType : undefined,
      isMonitored: isMonitored,
      order: order,
      orderBy: orderBy,
    })
    this.getPage(this.state.limit, this.state.offset, ()=>{});
  }

  render() {

    let headerPaddingStyle = {
      'paddingRight':'15px',
      'paddingLeft':'15px'
    }

    return(
      <Grid container spacing={24}>
        <TitleBar
          buttons={
            <Admin organizationID={this.props.match.params.organizationID}>
              <TitleBarButton
                key={1}
                label="Create"
                icon={<Plus />}
                to={`/organizations/${this.props.match.params.organizationID}/gateways/create`}
              />
            </Admin>
          }
        >
          <TitleBarTitle title="Gateways" />
        </TitleBar>
        <Grid item xs={12}>
          <GatewayFilter organizationID={this.props.match.params.organizationID}
                         onSearch={this.onSearch} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell style={headerPaddingStyle}>Name</TableCell>
                <TableCell style={headerPaddingStyle}>Gateway ID</TableCell>
                <TableCell style={headerPaddingStyle}>Description</TableCell>
                <TableCell style={headerPaddingStyle}>Last Seen</TableCell>
                <TableCell style={headerPaddingStyle}>Monitored</TableCell>
                <TableCell style={headerPaddingStyle} className={this.props.classes.chart}>Gateway activity (30d)</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListGateways);
