import React, {Component} from "react";

import Grid from "@material-ui/core/Grid";
import {withStyles} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Form from "../../components/Form";
import theme from "../../theme";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FormLabel} from "@material-ui/core";
import PersisterStore from "../../stores/PersisterStore";
import {AsyncParser} from "json2csv";
import DeviceStore from "../../stores/DeviceStore";
import fileDownload from "js-file-download";
import { InlineDatePicker } from "material-ui-pickers";
import moment from "moment";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import RolledRowsTable from "./RolledRowsTable";
import ArrowUp from "mdi-material-ui/ChevronUp";
import ArrowDown from "mdi-material-ui/ChevronDown";

const styles = {
    buttons: {
        textAlign: "right",
    },
    button: {
        marginLeft: 2 * theme.spacing.unit,
    },
    icon: {
        marginRight: theme.spacing.unit,
    },
    center: {
        textAlign: "center",
    },
    progress: {
        marginTop: 4 * theme.spacing.unit,
    },
    formLabel: {
        fontSize: 12,
    },
    smallIcon: {
        verticalAlign: 'bottom'
    }
};

const DATAUP_HEADER = [{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created'}, {value: 'dataAsHex', label: 'Data as hex'}, {value: 'data', label: 'Data'}, {value: 'fPort', label: 'Port'}, {value: 'fCnt', label: 'Frame counter'}, {value: 'txInfo.frequency', label: 'Frequency'}, {value: 'txInfo.dr', label: 'Data rate'}]
const DATADOWN_HEADER = [{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created'}, {value: 'confirmed', label: 'Confirmed'}, {value: 'data', label: 'Data'}, {value: 'fPort', label: 'Port'}]
const JOIN_HEADER = [{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created'}, {value: 'devAddr', label: 'Device address'}, {value: 'messageType', label: 'Message type'}]
const STATUS_HEADER = [{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created'}, {value: 'margin', label: 'Margin'}, {value: 'externalPowerSource', label: 'External power source'}, {value: 'batteryLevel', label: 'Battery level'}]
const ERROR_HEADER = [{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created'}, {value: 'type', label: 'Type'}, {value: 'error', label: 'Error'}, {value: 'fCnt', label: 'Frame counter'}]
const ACK_HEADER = [{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created'}, {value: 'acknowledged', label: 'Acknowledged'}, {value: 'fCnt', label: 'Frame counter'}]

const NOTIFICATION_TYPE_DATA_UP = {label: 'Uplink', urlSubPath: 'dataup'}
const NOTIFICATION_TYPE_DATA_DOWN = {label: 'Downlink', urlSubPath: 'datadown'}
const NOTIFICATION_TYPE_JOIN = {label: 'Join ', urlSubPath: 'join'}
const NOTIFICATION_TYPE_STATUS = {label: 'Status', urlSubPath: 'status'}
const NOTIFICATION_TYPE_ERROR = {label: 'Error', urlSubPath: 'error'}
const NOTIFICATION_TYPE_ACK = {label: 'Ack', urlSubPath: 'ack'}

class DevicePersistedData extends Component {

    state = {
        notificationType: NOTIFICATION_TYPE_DATA_UP.urlSubPath,
        notificationHeader: DATAUP_HEADER,
        fromDate: this.defaultDate(),
        toDate: this.defaultDate(),
        limit: 10,
        offset: 0,
        order: 'desc',
        persistDataList: undefined,
        rolledRow: undefined,
        search: false
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.showInTable = this.showInTable.bind(this);
        this.getPage = this.getPage.bind(this);
    }

    defaultDate() {
        return moment().format('YYYY-MM-DD')
    }

    downloadCsv(data, notificationType) {
        let opts;
        const fields= [
            "applicationID",
            "devEUI",
            "rxInfo.gatewayID",
            "rxInfo.time",
            "rxInfo.rssi",
            "rxInfo.loRaSNR",
            "txInfo.frequency",
            "txInfo.dr",
            "adr",
            "fCnt",
            "fPort",
            "data",
            "createdAt",
            "id",
            "dataAsHex"];

        if(notificationType === NOTIFICATION_TYPE_DATA_UP.urlSubPath){
            opts = {
                fields,
                flatten: true,
                delimiter: ';',
                unwind: 'rxInfo'
            };
        }
        else {
            opts = {
                flatten: true,
                delimiter: ';',
                unwind: 'rxInfo'
            };
        }

        const asyncParser = new AsyncParser(opts);

        let csv = '';
        try {
            asyncParser.processor
              .on('data', chunk => (csv += chunk.toString()))
              .on('end', () => fileDownload(csv, `notificationType-${notificationType}_device-${this.props.match.params.devEUI}.csv`));

            asyncParser.input.push(data);
            asyncParser.input.push(null);
        } catch(err){
            console.error(err);
            DeviceStore.notifyError("Fail to generate CSV from JSON data")
        }
    }


    onSubmit(e) {
        const {device} = this.props;
        e.preventDefault();

        let filter = {
            from: this.state.fromDate + 'T00:00:00.000%2B02:00',
            to: this.state.toDate + 'T23:59:59.999%2B02:00'
        }

        PersisterStore.getPersisterDataService(
          device.devEUI,
          this.state.notificationType,
          filter,
          (data) => {
              if (data.items.length < 1) {
                  PersisterStore.notify("No data for selected criteria!")
              } else {
                  this.downloadCsv(JSON.stringify(data.items), this.state.notificationType)
              }
          })
    }

    showInTable(e) {
        switch(this.state.notificationType) {
            case 'datadown':
                this.setState({notificationHeader: DATADOWN_HEADER});
                break;
            case 'join':
                this.setState({notificationHeader: JOIN_HEADER});
                break;
            case 'status':
                this.setState({notificationHeader: STATUS_HEADER});
                break;
            case 'error':
                this.setState({notificationHeader: ERROR_HEADER});
                break;
            case 'ack':
                this.setState({notificationHeader: ACK_HEADER});
                break;
            default:
                this.setState({notificationHeader: DATAUP_HEADER});
        }

        this.getPage(10, 0);
    }

    getPage(limit, offset, order = this.state.order) {
        let filter = {
            from: this.state.fromDate + 'T00:00:00.000%2B01:00',
            to: this.state.toDate + 'T23:59:59.999%2B01:00'
        }

        PersisterStore.getPersisterDataServiceForTable(
          this.props.device.devEUI,
          this.state.notificationType,
          filter,
          limit,
          offset,
          order,
          true,
          (data) => {
              if (data.items.length < 1) {
                  PersisterStore.notify("No data for selected criteria!")
                  this.setState({persistDataList: undefined, offset: offset, limit: limit})
              } else {
                  this.setState({persistDataList: data, offset: offset, limit: limit})
              }
          })

    }

    setOrder(order){
        this.setState({order: order});
        this.getPage(this.state.limit, this.state.offset, order);
    }

    render() {
        return (
          <Grid container spacing={24}>
              <Grid item xs={12}>
                  <Card className={this.props.classes.card}>
                      <CardContent>
                          <Form
                            submitLabel={'Export CSV'}
                            onSubmit={this.onSubmit}
                            extraButtons={<Button color="primary" onClick={this.showInTable}>Show in table</Button>}
                          >

                              <FormControl
                                fullWidth
                                margin="normal"
                                required>

                                  <FormLabel className={this.props.classes.formLabel} required>Notification type</FormLabel>
                                  <Select
                                    value={this.state.notificationType}
                                    onChange={(e) => this.setState({notificationType: e.target.value })}
                                    name="notification-type"
                                    inputProps={{
                                        id: 'notificationType',
                                    }}
                                  >
                                      <MenuItem value={NOTIFICATION_TYPE_DATA_UP.urlSubPath}>{NOTIFICATION_TYPE_DATA_UP.label}</MenuItem>
                                      <MenuItem value={NOTIFICATION_TYPE_DATA_DOWN.urlSubPath}>{NOTIFICATION_TYPE_DATA_DOWN.label}</MenuItem>
                                      <MenuItem value={NOTIFICATION_TYPE_JOIN.urlSubPath}>{NOTIFICATION_TYPE_JOIN.label}</MenuItem>
                                      <MenuItem value={NOTIFICATION_TYPE_STATUS.urlSubPath}>{NOTIFICATION_TYPE_STATUS.label}</MenuItem>
                                      <MenuItem value={NOTIFICATION_TYPE_ACK.urlSubPath}>{NOTIFICATION_TYPE_ACK.label}</MenuItem>
                                      <MenuItem value={NOTIFICATION_TYPE_ERROR.urlSubPath}>{NOTIFICATION_TYPE_ERROR.label}</MenuItem>
                                  </Select>
                              </FormControl>

                              <InlineDatePicker
                                fullWidth
                                margin="normal"
                                label="From"
                                value={this.state.fromDate}
                                format="DD/MM/YYYY"
                                onChange={(data) => {this.setState({fromDate: data.format('YYYY-MM-DD')})}} />

                              <InlineDatePicker
                                fullWidth
                                margin="normal"
                                label="To"
                                value={this.state.toDate}
                                format="DD/MM/YYYY"
                                onChange={(data) => {this.setState({toDate: data.format('YYYY-MM-DD')})}} />
                              <FormHelperText ></FormHelperText>
                              <FormHelperText>
                                  Note that export notifications is limited to 10 000.
                              </FormHelperText>
                          </Form>
                      </CardContent>
                  </Card>
              </Grid>
              { this.state.persistDataList &&
              <Grid item xs={12}>
                  <RolledRowsTable
                    header={
                        <TableRow>
                            {this.state.notificationHeader.map((item)=>
                              item.value === 'createdAt'
                                ? <TableCell key={item.value}>{item.label}
                                    { this.state.order === 'desc'
                                      ?  <ArrowDown fontSize={'small'} className={this.props.classes.smallIcon} onClick={()=>this.setOrder('asc')}/>
                                      :  <ArrowUp fontSize={'small'} className={this.props.classes.smallIcon} onClick={()=>this.setOrder('desc')}/>
                                    }
                                </TableCell>
                                : <TableCell key={item.value}>{item.label}</TableCell>
                            )}
                            {this.state.notificationHeader === DATAUP_HEADER && <TableCell></TableCell>}
                        </TableRow>
                    }
                    getPage={this.getPage}
                    data={this.state.persistDataList}
                    configNotification={(this.state.notificationHeader)}
                    limit={this.state.limit}
                    page={this.state.offset/this.state.limit}
                  />
              </Grid>
              }
          </Grid>
        );
    }

}

export default withStyles(styles)(DevicePersistedData);
