import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from "@material-ui/core//Button";
import theme from "../../theme";
import OutlinedInput from "@material-ui/core/OutlinedInput";

class GatewayFilter extends Component {
    constructor() {
        super();
        this.state = {
            searchFilter: '',
            connectionType: 'all',
            isMonitored: 'all',
            orderBy: 'last_seen_at',
            order: 'desc',
        };

        this.onSearch = this.onSearch.bind(this);
    }

    onChange(e){
        this.setState({
            [e.target.name]:e.target.value
        });
    }

    onSearch() {
        this.props.onSearch(this.state.searchFilter, this.state.connectionType, this.state.isMonitored, this.state.orderBy, this.state.order)
    }

    render() {
        return(
          <Paper >
              <div style={{padding: '30px'}}>
                  <Grid container>
                      <Grid item xs={7}>
                          <FormLabel style={{color: theme.palette.primary.main}}>
                              Filter
                          </FormLabel>
                          <Grid container>
                              <Grid item xs={6} style={{'paddingTop': '10px', width: '100%'}}>
                                  <TextField
                                    id="searchFilter"
                                    name="searchFilter"
                                    label="Name/ ID Gateway/ Description/ IP Address/ Phone Number"
                                    value={this.state.searchFilter}
                                    onChange={(e)=>this.onChange(e)}
                                    margin = "dense"
                                    style={{paddingRight: "20px", height:'80px', width: '100%'}}
                                    InputLabelProps={{
                                        shrink: true,
                                        style:{
                                            width: "max-content"
                                        }
                                    }}
                                    inputProps={{
                                        style: {
                                            height: '26px',
                                        }
                                    }}
                                    placeholder="Name/ ID Gateway/ Description/ IP Address/ Phone Number"
                                    variant="outlined"
                                  />
                              </Grid>
                              <Grid item xs={3} style={{'paddingTop': '10px', width: '100%'}}>
                                  <FormControl variant="outlined" style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                                      <InputLabel htmlFor="connectionType">
                                          Connection Type
                                      </InputLabel>
                                      <Select
                                        value={this.state.connectionType}
                                        onChange={(e)=>this.onChange(e)}
                                        input={<OutlinedInput labelWidth={120}
                                                              name="connectionType"
                                                              id="connectionType"
                                                              style={{'backgroundColor':'white'}}
                                        />}
                                      >
                                          <MenuItem value='all'><em>None</em></MenuItem>
                                          <MenuItem value='ETH'>ETH</MenuItem>
                                          <MenuItem value='4G'>4G</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Grid>
                              <Grid item xs={3} style={{'paddingTop': '10px', width: '100%'}}>
                                  <FormControl variant="outlined" style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                                      <InputLabel htmlFor="isMonitored">
                                          Monitored
                                      </InputLabel>
                                      <Select
                                        value={this.state.isMonitored}
                                        onChange={(e)=>this.onChange(e)}
                                        input={<OutlinedInput labelWidth={80}
                                                              name="isMonitored"
                                                              id="isMonitored"
                                                              style={{'backgroundColor':'white'}}
                                        />}
                                      >
                                          <MenuItem value='all'><em>All</em></MenuItem>
                                          <MenuItem value='true'>yes</MenuItem>
                                          <MenuItem value='false'>no</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Grid>
                          </Grid>
                      </Grid>
                      <Grid item xs={5}>
                          <FormLabel  style={{color: theme.palette.primary.main}}>
                              Sorting
                          </FormLabel>
                          <Grid container style={{'paddingTop': '10px'}}>
                              <Grid item xs={4}>
                                  <FormControl variant="outlined" style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                                      <InputLabel htmlFor="orderBy">
                                          Order by
                                      </InputLabel>
                                      <Select
                                        value={this.state.orderBy}
                                        onChange={(e)=>this.onChange(e)}
                                        input={<OutlinedInput labelWidth={60}
                                                              name="orderBy"
                                                              id="orderBy"
                                                              style={{'backgroundColor':'white'}}
                                        />}
                                      >
                                          <MenuItem value='last_seen_at'>Last Seen</MenuItem>
                                          <MenuItem value='gateway_id'>Gateway ID</MenuItem>
                                          <MenuItem value='name'>Name</MenuItem>
                                          <MenuItem value='description'>Description</MenuItem>
                                          <MenuItem value='is_monitored'>Monitored</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Grid>
                              <Grid item xs={4}>
                                  <FormControl variant="outlined" style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                                      <InputLabel htmlFor="order">
                                          Order
                                      </InputLabel>
                                      <Select
                                        value={this.state.order}
                                        onChange={(e)=>this.onChange(e)}
                                        input={<OutlinedInput labelWidth={50}
                                                              name="order"
                                                              id="order"
                                                              style={{'backgroundColor':'white'}}
                                        />}
                                      >
                                          <MenuItem value='desc'>desc</MenuItem>
                                          <MenuItem value='asc'>asc</MenuItem>
                                      </Select>
                                  </FormControl>
                              </Grid>
                              <Grid direction="column"
                                    justify="center"
                                    container
                                    item xs={4}
                                    style={{color: 'white', display: 'flex'}}>
                                  <Button color="primary"
                                          variant={'contained'}
                                          style={{color: 'white', 'alignSelf': 'end', 'marginLeft': '20px'}}
                                          onClick={this.onSearch}>
                                      Search
                                  </Button>
                              </Grid>
                          </Grid>
                      </Grid>
                  </Grid>
              </div>
          </Paper>
        );
    }
}

export default GatewayFilter;
