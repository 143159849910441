import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';

import { CardContent } from "@material-ui/core";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import OrganizationStore from "../../stores/OrganizationStore";
import Form from "../../components/Form";
import TextField from "@material-ui/core/TextField";


class CreateTemplate extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      template: undefined
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    OrganizationStore.get(this.props.match.params.templateID, resp => {
      this.setState({
        template: resp,
      });
    });
  }

  onSubmit() {
    OrganizationStore.createFromTemplate(this.state.name, this.props.match.params.templateID,resp => {
      this.props.history.push("/templates");
    });
  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar>
          <TitleBarTitle to="/templates" title="Templates" />
          <TitleBarTitle title="/" />
          <TitleBarTitle to={"/templates/"+this.props.match.params.templateID}  title={this.state.template ? this.state.template.organization.name : ''} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Create Organization" />
        </TitleBar>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Form
                submitLabel={'Create Organization from Template'}
                onSubmit={this.onSubmit}
              >
                <TextField
                  id="templateID"
                  label="Create from template"
                  margin="normal"
                  value={this.state.template ? this.state.template.organization.name : ''}
                  readOnly
                  fullWidth
                />
                <TextField
                  id="name"
                  label="Organization name"
                  helperText="The name may only contain words, numbers and dashes."
                  margin="normal"
                  value={this.state.name || ""}
                  onChange={(e) => {this.setState({name: e.target.value})}}
                  inputProps={{
                    pattern: "[\\w-]+",
                  }}
                  required
                  fullWidth
                />
              </Form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(CreateTemplate);