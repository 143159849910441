import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {InlineDatePicker} from "material-ui-pickers";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import green from "@material-ui/core/colors/green";

const OwnSwitch = withStyles({
    switchBase: {
        color: green[300]
    }
})(Switch);

class StatisticsFilter extends Component {
    render() {
        return(
            <div style={{padding: '10px 30px'}}>
                <Grid container>
                    <Grid item xs={2}>
                        <FormControl style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                            <InlineDatePicker
                                fullWidth
                                margin="normal"
                                label="From"
                                value={this.props.from}
                                format="DD/MM/YYYY"
                                onChange={(data) => {this.props.setDate(data, this.props.to)}} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                            <InlineDatePicker
                              fullWidth
                              margin="normal"
                              label="To"
                              value={this.props.to}
                              format="DD/MM/YYYY"
                              onChange={(data) => {this.props.setDate(this.props.from, data)}} />
                        </FormControl>
                    </Grid>
                    <Grid direction="row"
                          container
                          item xs={5}
                          style={{color: 'white', display: 'flex', alignContent: 'flex-end'}}>
                        <Button color="primary"
                                variant={'contained'}
                                style={{color: 'white', 'alignSelf': 'end', 'marginLeft': '20px', 'marginBottom': '10px'}}
                                onClick={()=>{this.props.setDate(new Date(), new Date())}}>
                            Current Day
                        </Button>
                        <Button color="primary"
                                variant={'contained'}
                                style={{color: 'white', 'alignSelf': 'end', 'marginLeft': '20px', 'marginBottom': '10px'}}
                                onClick={()=>{this.props.setDate(new Date(new Date().setDate(new Date().getDate()-new Date().getDay()+1)), new Date())}}>
                            Current Week
                        </Button>
                        <Button color="primary"
                                variant={'contained'}
                                style={{color: 'white', 'alignSelf': 'end', 'marginLeft': '20px', 'marginBottom': '10px'}}
                                onClick={()=>{this.props.setDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1).toJSON(), new Date())}}>
                            Current Month
                        </Button>
                    </Grid>
                    <Grid direction="row"
                          container
                          item xs={2}
                          style={{color: 'white', display: 'flex', alignContent: 'flex-end'}}>
                        <Typography component="div">
                            <Grid component="label" container alignItems="center" style={{'marginLeft': '20px'}}>
                                <Grid item>Table</Grid>
                                <Grid item>
                                    <OwnSwitch
                                      checked={this.props.isBar}
                                      onChange={(e)=>{this.props.setMode(e.target.checked)}}
                                      color={'primary'}
                                    />
                                </Grid>
                                <Grid item>Graph</Grid>
                            </Grid>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default StatisticsFilter;
