import React, { Component } from "react";

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Import from "mdi-material-ui/Import";
import Export from "mdi-material-ui/Export";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import DeviceStore from "../../stores/DeviceStore";
import SimpleTable from "../../components/SimpleTable";
import ApplicationStore from "../../stores/ApplicationStore";
import theme from "../../theme";
import fileDownload from "js-file-download";

class DeviceImportRow extends Component {
  constructor() {
    super();

    this.state = {};
  }

  render() {
    let device = this.props.device;
    let errorResponseStyle = {
      color: theme.palette.secondary.main
    }

    let okResponseStyle = {
      color: theme.palette.primary.main
    }

    return (
      <TableRow key={device.device.devEUI}>
        <TableCell>{device.device ? device.device.devEUI : ''}</TableCell>
        <TableCell>{device.device ? device.device.name : ''}</TableCell>
        <TableCell>{device.device ? device.device.description : ''}</TableCell>
        <TableCell>{device.device ? device.deviceProfileName : ''}</TableCell>
        <TableCell style={device.responseCode === 0 ? okResponseStyle : errorResponseStyle}>{device.responseMessage}</TableCell>
      </TableRow>
    );
  }
}


class ListDeviceImport extends Component {
  constructor() {
    super();
    this.state = {
      application: undefined,
      apiResult: {
        devices: [],
      }
    }

    this.importDevices = this.importDevices.bind(this);
    this.getRows = this.getRows.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
  }

  componentWillMount() {
    ApplicationStore.get(this.props.match.params.applicationID, resp => {
      this.setState({
        application: resp,
      });
    });
  }

  importDevices(json) {
    if(json.devices && json.devices.length > 100){
      DeviceStore.notifyImportOutOfLimit(100);
      this.setState({
        apiResult: {
          devices: [],
        }
      })
    }
    else {
      DeviceStore.deviceImport(json, (result) => {
        let devices = [];

        if (result.devices && result.devices.length > 0) {
          result.devices.map((device) => {
            return devices.push(device);
          });
        }

        this.setState({
          apiResult: {
            devices: devices,
          }
        })

      });
    }
  }

  getRows() {
    return this.state.apiResult.devices.map((dev)=>{
      return(
        <DeviceImportRow device={dev} key={dev.device.devEUI}/>
      );
    })

  }

  excelFormatFromResult(deviceItem) {
    let device = {};
    if (deviceItem) {
      device['Device EUI'] = deviceItem.device ? deviceItem.device.devEUI : '';
      device['Name'] = deviceItem.device ? deviceItem.device.name : '';
      device['Description'] = deviceItem.device ? deviceItem.device.description : '';
      device['Disable frame-counter validation'] = deviceItem.device ? deviceItem.device.skipFCntCheck : '';

      device['Device Profile'] = deviceItem.deviceProfileName ? deviceItem.deviceProfileName : '';

      device['Network key'] = deviceItem.deviceKeys ? deviceItem.deviceKeys.nwkKey : '';
      device['Application key'] = deviceItem.deviceKeys ? deviceItem.deviceKeys.appKey : '';

      device['Device address'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.devAddr : '';
      device['Application session key'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.appSKey : '';
      device['Network session key'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.nwkSEncKey : '';
      device['Serving network session integrity key'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.sNwkSIntKey : '';
      device['Forwarding network session integrity key'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.fNwkSIntKey : '';
      device['Uplink frame-counter'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.fCntUp : '';
      device['Downlink frame-counter (network)'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.nFCntDown : '';
      device['Downlink frame-counter (application)'] = deviceItem.deviceActivation ? deviceItem.deviceActivation.aFCntDown : '';
      device['Response Message'] = deviceItem.responseMessage ? deviceItem.responseMessage : '';
    }
    return device;
  }

  resultToPostFormat(devices) {
    let result = {};

    result.organizationID = this.props.match.params.organizationID;
    result.devices = [];

    if (devices && devices.length>0) {
      devices.map((device) => {
        if(device['Name']){
          let deviceItem = {};

          deviceItem.device = {};
          deviceItem.deviceKeys = {};
          deviceItem.deviceActivation = {};

          deviceItem.device.devEUI = device['Device EUI'] ? device['Device EUI'] : '';
          deviceItem.device.name = device['Name'] ? device['Name'] : '';
          deviceItem.device.description = device['Description'] ? device['Description'] : '';
          deviceItem.device.skipFCntCheck = device['Disable frame-counter validation'] ? JSON.parse(device['Disable frame-counter validation']) : false;
          deviceItem.device.applicationID = parseInt(this.props.match.params.applicationID);

          if(device['Network key'] || device['Application key']) {
            deviceItem.deviceKeys.nwkKey = device['Network key'] ? device['Network key'] : '';
            deviceItem.deviceKeys.appKey = device['Application key'] ? device['Application key'] : '';
          }
          else{
            deviceItem.deviceKeys = null;
          }

          if(device['Device address'] || device['Application session key'] || device['Network session key']) {
            deviceItem.deviceActivation.devAddr = device['Device address'] ? device['Device address'] : '';
            deviceItem.deviceActivation.appSKey = device['Application session key'] ? device['Application session key'] : '';
            deviceItem.deviceActivation.nwkSEncKey = device['Network session key'] ? device['Network session key'] : '';
            deviceItem.deviceActivation.sNwkSIntKey = device['Serving network session integrity key'] ? device['Serving network session integrity key'] : '';
            deviceItem.deviceActivation.fNwkSIntKey = device['Forwarding network session integrity key'] ? device['Forwarding network session integrity key'] : '';
            deviceItem.deviceActivation.fCntUp = device['Uplink frame-counter'] ? parseInt(device['Uplink frame-counter']) : 0;
            deviceItem.deviceActivation.nFCntDown = device['Downlink frame-counter (network)'] ? parseInt(device['Downlink frame-counter (network)']) : 0;
            deviceItem.deviceActivation.aFCntDown = device['Downlink frame-counter (application)'] ? parseInt(device['Downlink frame-counter (application)']) : 0;
          }
          else{
            deviceItem.deviceActivation = null;
          }

          deviceItem.deviceProfileName = device['Device Profile'] ? device['Device Profile'] : '';

          return result.devices.push(deviceItem);
        }
      });
    }
    return result;
  }

  onInputClick(event) {
    event.target.value = ''
  }

  handleImport(event) {
    this.setState({
      apiResult: {
        devices: [],
      }
    });

    let file =  event.target.files[0];
    const scope = this;
    let reader = new FileReader();
    reader.onload = function (e) {
      let csv = e.target.result;
      let lines = csv.split("\n");
      let result = [];
      let headers=lines[0].split(",");
      for(let i=1;i<lines.length;i++){
        let obj = {};
        let currentline=lines[i].split(",");
        for(let j=0;j<headers.length;j++){
          obj[headers[j].trim()] = currentline[j] ? currentline[j].trim() : undefined;
        }
        result.push(obj);
      }
      let postFormat = scope.resultToPostFormat(result);
      scope.importDevices(postFormat);
    };
    reader.readAsText(file);
  }

  handleExport() {
    let rows = this.state.apiResult.devices.filter((device) => device.responseCode > 0);
    let headers, csvRecord;

    //let csvContent = "data:text/csv;charset=utf-8,";

    if (rows.length>0) {
      headers = Object.keys(this.excelFormatFromResult(rows[0])).join(',') + '\n';
      csvRecord = headers;

      rows.map(e => {
        let row = this.excelFormatFromResult(e);
        return csvRecord += Object.values(row).join(',') + '\n';
      });
    }
    fileDownload(csvRecord, "not_imported_data.csv");

    /*let encodedUri = encodeURI(csvContent + csvRecord);

    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "not_imported_data.csv");
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);*/

  }

  render() {
    if (this.state.application === undefined) {
      return(<div></div>);
    }

    let canExport = this.state.apiResult.devices.length > 0 &&
      this.state.apiResult.devices.find((device) => {return device.responseCode > 0});

    return(
      <Grid container spacing={24}>

        <TitleBar>
          <TitleBarTitle title="Applications" to={`/organizations/${this.props.match.params.organizationID}/applications`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={this.state.application.application.name} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Devices" to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}`} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title="Device-Import" />
        </TitleBar>

        <TitleBar
          buttons={
            <React.Fragment>
              { canExport &&
              <TitleBarButton
                label="Export not imported"
                icon={<Export/>}
                color="secondary"
                onClick={() => this.handleExport()}
              />
              }
              <input id={'device-import'}
                     type="file"
                     accept=".csv"
                     ref={(ref) => this.importInput = ref}
                     style={{display: 'none'}}
                     onClick={(e) => this.onInputClick(e)}
                     onChange={(e) => this.handleImport(e)}
              />
              <TitleBarButton
                label="Import"
                icon={<Import />}
                onClick = {() => {this.importInput.click()}}
              />
            </React.Fragment>
          }
        >

        </TitleBar>
        {this.state.apiResult.devices &&
        <Grid item xs={12}>
          <SimpleTable
            header={
              <TableRow>
                <TableCell>Device EUI</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Device Profile</TableCell>
                <TableCell>Response</TableCell>
              </TableRow>
            }
            rows={this.getRows()}
          />
        </Grid>
        }
      </Grid>
    );
  }
}

export default ListDeviceImport;
