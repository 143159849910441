import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core//Button";
import theme from "../../theme";
import {InlineDatePicker} from "material-ui-pickers";;

class InvoicesFilter extends Component {
    constructor() {
        super();
        this.state = {
            date: new Date().toJSON().slice(0,7)
        };

        this.onSearch = this.onSearch.bind(this);
    }

    onChange(e){
        this.setState({
            [e.target.name]:e.target.value
        });
    }

    onSearch() {
        let parseDate = this.state.date.split('-');
        this.props.onSearch(parseDate[1], parseDate[0]);
    }

    render() {
        return(
            <Paper >
                <div style={{padding: '30px'}}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormLabel  style={{color: theme.palette.primary.main}}>
                                Month for statistics
                            </FormLabel>
                            <Grid container style={{'paddingTop': '10px'}}>
                                <Grid item xs={2}>
                                    <FormControl variant="outlined" style={{'paddingRight': '20px', 'marginTop': '8px', width: '100%'}}>
                                        <InlineDatePicker
                                          fullWidth
                                          margin="normal"
                                          label="Date"
                                          value={this.state.date}
                                          format="MM/YYYY"
                                          views={['year', 'month']}
                                          onChange={(data) => {this.setState({date: data.format('YYYY-MM')})}} />
                                    </FormControl>
                                </Grid>
                                <Grid direction="column"
                                      justify="center"
                                      container
                                      item xs={4}
                                      style={{color: 'white', display: 'flex', 'alignSelf': 'flex-end'}}>
                                    <Button color="primary"
                                            variant={'contained'}
                                            style={{color: 'white', 'alignSelf': 'end', 'marginLeft': '20px', 'marginBottom': '10px'}}
                                            onClick={this.onSearch}>
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Paper>
        );
    }
}

export default InvoicesFilter;
