import React, { Component } from "react";
import { Link } from 'react-router-dom';

import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles';

import theme from "../theme";


const styles = {
  link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      verticalAlign: 'middle',
      display: 'inline-flex',
      alignItems: 'center'
  },
  linkSecondary: {
      textDecoration: "none",
      color: theme.palette.secondary.main,
      verticalAlign: 'middle',
      display: 'inline-flex',
      alignItems: 'center'
  },
};


class TableCellLink extends Component {
  render() {
    return(
      <TableCell style={this.props.style}>
          <Link className={this.props.color === 'secondary' ? this.props.classes.linkSecondary : this.props.classes.link} to={this.props.to}>{this.props.children}</Link>
      </TableCell>
    );
  }
}

export default withStyles(styles)(TableCellLink);
