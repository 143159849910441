import sessionStore from "./SessionStore";
import {checkStatus, errorHandler} from "./helpers";
import dispatcher from "../dispatcher";

class DeviceQueue {

    enqueue(device, body, callbackFnc){
        fetch('/api/devices/' + device.devEUI + '/queue',
            {
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                    'Authorization':sessionStore.getToken()
                },
                body: JSON.stringify(body),
            })
            .then(res=> {
              if (res.status >= 200 && res.status < 300) {
                this.notify("enqueued");
                callbackFnc(res.obj)
              }
              return res;
            })
            .then(res=> res.json())
            .then((res) => {
               if(res.message) {
                    throw res;
                }
            }).catch(errorHandler)
    }

    massEnqueue(body, callbackFnc){
        fetch( '/api/devices/massqueue',
            {
                method: 'POST',
                headers:{
                  'Content-Type': 'application/json',
                  'Authorization':sessionStore.getToken()
            },
            body: JSON.stringify(body),
        })
        .then(checkStatus)
        .then(response => response.json())
        .then(responseData => {
            this.notify("mass enqueued");
            callbackFnc(responseData)
        }).catch(errorHandler)
    }

    notify(action) {
        dispatcher.dispatch({
            type: "CREATE_NOTIFICATION",
            notification: {
                type: "success",
                message: "device has been " + action,
            },
        });
    }

    notifyKeys(action) {
        dispatcher.dispatch({
            type: "CREATE_NOTIFICATION",
            notification: {
                type: "success",
                message: "device-keys have been " + action,
            },
        });
    }
}

const deviceQueue = new DeviceQueue();
export default deviceQueue;
