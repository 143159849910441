import React, { Component } from "react";
import Grid from '@material-ui/core/Grid';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import InvoicesFilter from "./InvoicesFilter";
import InvoiceStore from "../../stores/InvoiceStore";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SimpleTable from "../../components/SimpleTable";
import CheckIcon from "mdi-material-ui/Check";
import Close from "mdi-material-ui/Close";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import Export from "mdi-material-ui/Export";
import fileDownload from "js-file-download";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "../../components/Paper";

class InvoiceRow extends Component {
    render() {
        let invoice = this.props.invoice;

        return (
          <TableRow>
              <TableCellLink
                to={`/organizations/${invoice.organizationID}/applications/${invoice.applicationID}`}>
                  {invoice.applicationName}
              </TableCellLink>
              <TableCellLink
                to={`/organizations/${invoice.organizationID}`}>
                  {invoice.organizationName}
              </TableCellLink>
              <TableCell>{invoice.isInvoicing ? <CheckIcon/> :<Close/>}</TableCell>
              <TableCell>{invoice.invoicingProfile}</TableCell>
              <TableCell>{invoice.invoicingMetricsValue}</TableCell>
          </TableRow>
        );
    }
}

class ListInvoices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            invoices: [],
            loaded: 0
        };

        this.onSearch = this.onSearch.bind(this);
        this.excelFormatFromResult = this.excelFormatFromResult.bind(this);
        this.handleExport = this.handleExport.bind(this);
    }

    onSearch(month, year){
        this.setState({loaded: this.state.loaded + 1});
        InvoiceStore.list(month, year, (resp) => {
            if (resp.result && resp.result.length > 0) {
                this.setState({
                    invoices: resp.result,
                    loaded: this.state.loaded - 1
                })
            }
        });
    }

    getResponse() {
        return this.state.invoices.map((invoice, i)=>{
            return(
              <InvoiceRow invoice={invoice} key={i}/>
            );
        })

    }

    excelFormatFromResult(item) {
        let invoice = {};
        if (item) {
            invoice['Application Name'] = item.applicationName ? item.applicationName : '';
            invoice['Organization Name'] = item.organizationName ? item.organizationName : '';
            invoice['Invoicing'] = item.isInvoicing ? 'YES' : 'NO';
            invoice['Invoicing Profile'] = item.invoicingProfile ? item.invoicingProfile : '';
            invoice['Active Devices'] = item.invoicingMetricsValue ? item.invoicingMetricsValue : '0';
        }
        return invoice;
    }

    handleExport() {
        let rows = this.state.invoices;
        let csvRecord;

        //let csvContent = "data:application/csv;charset=utf-8,";

        if (rows.length>0) {
            csvRecord = Object.keys(this.excelFormatFromResult(rows[0])).join(',') + '\n';

            rows.map(e => {
                let row = this.excelFormatFromResult(e);
                return csvRecord += Object.values(row).join(',') + '\n';
            });
        }
        //let encodedUri = encodeURI(csvContent + csvRecord);

        fileDownload(csvRecord, `invoices.csv`);
        /*var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "invoices.csv");
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);*/
    }


    render() {
        return(
          <Grid container spacing={24}>
              <TitleBar><TitleBarTitle title="Invoices" /></TitleBar>
              <Grid item xs={12}>
                  <InvoicesFilter onSearch={this.onSearch} />
              </Grid>
              { this.state.invoices.length > 0 && this.state.loaded === 0 &&
              <Grid item xs={12}>
                  <SimpleTable
                    header={
                        <TableRow>
                            <TableCell>Application Name</TableCell>
                            <TableCell>Organization Name</TableCell>
                            <TableCell>Invoicing</TableCell>
                            <TableCell>Invoicing Profile</TableCell>
                            <TableCell>Active Devices</TableCell>
                        </TableRow>
                    }
                    rows={this.getResponse()}
                    withoutPagination={true}
                  >
                      <div style={{textAlign: 'right'}}>
                          <TitleBarButton

                            label="Export Invoices Report"
                            icon={<Export />}
                            onClick={this.handleExport}
                          />
                      </div>
                  </SimpleTable>
              </Grid>
              }
              {this.state.loaded !== 0 &&
              <Grid item xs={12} style={{textAlign: "center"}}>
                  <Paper>
                      <CircularProgress/>
                  </Paper>
              </Grid>
              }
          </Grid>
        );
    }
}

export default ListInvoices;
